.modal_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .modal_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(86, 26, 76, 0.15);
    backdrop-filter: blur(4px);
    z-index: -1;
  }
  
  .modal_content {
    display: flex;
    flex-direction: column;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 60px;
    padding-bottom: 120px;
    border-radius: 15px;
    border: 1px solid var(--primaryColorLight);
    background-color: white;
    width: 650px;
    min-height: 330px;
    z-index: 10;
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  .header_text {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .buttons_container {
    position: absolute;
    bottom: 20px;
    right: 60px;
    display: flex;
    gap: 10px;
  }
  
  .cancel,
  .update,
  .disabled {
    font-size: 18px;
    outline: none;
    border: none;
    font-weight: bold;
    padding: 15px;
    width: 150px;
    border-radius: var(--borderRadiusPillButton);
    margin-left: 10px;
  }
  .cancel {
    color: var(--primaryColorBlue);
    background-color: var(--inactiveColorLight);
  }

  .update {
    background-color: var(--buttonColorPillDashboard);
    color: white;
  }
  .cancel:focus, .cancel:hover{
    outline: none;
    border: none;
    background-color: var(--inactiveColorMedium);      
  }
  .update:focus, .update:hover {
    outline: none;
    border: none;
    background-color: var(--primaryColorExtraBlue);      
  }

  .disabled{
    color: var(--primaryColorOffset);
    background-color: var(--primaryColorBlueLightish);
  }