@import './vars.css';
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  color: #1f1f30;
  font-size: 62.5%; /* 100% would be 16 px so now, 1 rem = 10px */
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}


body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  overflow-x: hidden; /* for wavey pattern (curve) overflow */
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

main {
  display: block; /* Render the main element consistently in IE */
}

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;/* Remove the gray background on active links in IE 10 */
}

a:hover{
  text-decoration: none;
}

img {

  border-style: none; /* Remove the border on images inside links in IE 10 */
}


b,
strong {
  font-weight: bolder; /* Add the correct font weight in Chrome, Edge, and Safari */
}


small {
  font-size: 80%; /* Add the correct font size in all browsers */
}

/* Change the font styles in all browsers, and remove the margin in Firefox and Safari */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/* Remove the inner padding in Chrome and Safari on macOS */
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}


/* Typography */
p {
  font-size: clamp(1.2rem, 1.5vw + 1rem, 1.8rem);
  color: var(--fontColorLightBlack);
  margin: 0;
}

h1, /* h2, */ h3, h4, h5, h6{
  color: var(--primaryColor);
}


h1{
  font-size: 6.4rem;
}

h2 {
  font-size: clamp(3.5rem, 4.5vw + 1rem, 6rem); 
}

h3 {
  font-size: clamp(3rem, 4vw + 1rem, 5.2rem); 
}

h4 {
  font-size: clamp(2.5rem, 3.5vw + 1rem, 4.5rem); 
}

h5 {
  font-size: clamp(2rem, 3vw + 1rem, 3.8rem); 
}

h6 {
  font-size: clamp(1.5rem, 2.5vw + 1rem, 2.6rem); 
}



/* copywright */
.romp_copywright{
  font-style: italic;
  margin-top: 5rem;
}

/* Mobile Devices up to 480px */
@media (max-width: 480px) {  
  h1{
    font-size: 4.8rem;
  }
}