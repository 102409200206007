.our_story_section{
    display: flex;
}


.our_story_flex_container{
    display: flex;
    flex-direction: column;
    margin-top: 12rem;
    padding: 0 20rem;
    gap: 5rem;
}

.mission_vision_container{
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.values_wrapper, .mission_wrapper, .vision_wrapper, .our_story_wrapper{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem;
    border-radius: var(--borderRadius);
    --webkit-box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.2),
    0 4px 8px rgba(0, 0, 0, 0.2),
    0 8px 16px rgba(0, 0, 0, 0.2),
    0 16px 32px rgba(0, 0, 0, 0.2);
    box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.2),
    0 4px 8px rgba(0, 0, 0, 0.2),
    0 8px 16px rgba(0, 0, 0, 0.2),
    0 16px 32px rgba(0, 0, 0, 0.2);
    border: 0.3rem solid white;
}

.mission_wrapper > *, .vision_wrapper > * {
    color: white;
}

.mission_wrapper{
    background-color: var(--primaryColor);
    flex: 1;
}

.vision_wrapper{
    background-color: var(--primaryColorFlip);
    flex: 1;
}

.values_wrapper{
    background-color: var(--primaryColorLight); 
}

.values_wrapper div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.our_story_wrapper{
    background-color: var(--primaryColorFlipLight);
    text-align: justify;
}


.our_story_main_img_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 100%;

}

.our_story_main_img{
    width: 100%;
    object-fit: cover;
    border: 0.5rem solid white;
 }

 .our_story_wrapper > div {
    display: flex;
    align-items: center;
    gap: 4rem;
 }

 .our_story_wrapper > div > p {
    flex: 1;
 }

 .our_story_text_img_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    flex: 1;
    
 }

 .our_story_text_img{
    width: 100%;
    object-fit: cover;
    border: 0.5rem solid white;
 }

 .our_story_header_text {
     font-weight: bold;
 }

/* COLUM LAYOUT for Our Story images on laptop/desktop screen re-size  */
  @media only screen and (max-width: 1250px){
    .our_story_flex_container{
        padding: 0 10rem;
    }
    .our_story_wrapper > div{
        flex-direction: column;
    }
    .our_story_text_img{
        width: 70%;
    }

    .values_wrapper div p {
        margin: 1rem 0rem;
        width: 30%;
    }

  }

 /* Smaller laptop/desktop screens  */
@media only screen and (max-width: 750px){
    .our_story_flex_container{
        padding: 0 2rem;
    }
}

/* Small Screens / Phones   */
@media only screen and (max-width: 550px){
    .our_story_text_img{
        width: 100%;
    }
}

/* COLUMN LAYOUT for Tiny Screens / Phones   */
@media only screen and (max-width: 450px){
    .mission_vision_container{
        flex-direction: column;
    }

    .values_wrapper div p {
        margin: 1rem 0rem;
        width: 40%;
    }
}



