.our_team_member_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 45rem;
    gap: 1rem;
    margin: 2rem 4rem;
    padding: 2rem;
    border-radius: var(--borderRadius);
    --webkit-box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.2),
    0 4px 8px rgba(0, 0, 0, 0.2),
    0 8px 16px rgba(0, 0, 0, 0.2),
    0 16px 32px rgba(0, 0, 0, 0.2);
    box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.2),
    0 4px 8px rgba(0, 0, 0, 0.2),
    0 8px 16px rgba(0, 0, 0, 0.2),
    0 16px 32px rgba(0, 0, 0, 0.2);
    border: 0.3rem solid white;
}

.our_team_member_container:nth-child(){
    background-color: var(--primaryColorLight);
}

.our_team_member_photo_container{
    display: grid;
    place-items: center;
    gap: 2rem;
    width: 100%;

}

.bio_pic{
    width: 100%;  
    object-fit: cover;
    height: 40rem;
    margin-bottom: 1rem;
}

.bio_name{
    background-color: var(--primaryColorMedium);
    color: white;
    padding: 1.5rem;
    border-radius: var(--borderRadius);
    width: 100%;
}

.font_standout{
    font-weight: bold;
}

.our_team_member_text_container{
    width: 100%;
    color: var(--primaryColor); 

}

.description_text {
    font-size: 1.6rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    line-height: 3.4rem; 
}

.read_more{
    display: inline-block;
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: bold;
    margin-left: 0.5rem;
    color: var(--primaryColorMedium);
}

/* Full Width Bio Pics - Smaller Screens / Phones   */
@media only screen and (max-width: 700px){
    .bio_name{
         width: 85%;
    }
    .our_team_member_text_container{
        padding: 0 4rem;
    }
}