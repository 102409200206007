.container{
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    padding: 3rem 0px 3rem 0px;
}

.calendar_container{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/* .incrementer_container{
    max-width: 60%;
} */

