.main_container{
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
    padding: 3rem 0px 3rem 0px;
    min-height: 500px;
}

.form_container{
    margin-top: 5rem;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.address_suggestions_container{
    margin-top: 3rem;
    padding: 0 1rem;
}

.address_suggestion{
    font-size: 1.6rem;
    padding: 0.5rem;
    cursor: pointer;
    margin-left: -0.5rem;
}

.address_suggestion:hover{
    background-color: var(--primaryColorLight);
}

.map_wrapper{
    height: 30rem;
}


.error_message{
    margin-top: 2rem;
    text-align: center;
    color: red;
}

.input_error_message{
    color: red;
    position: absolute;
}

/* Small Screens / Phones   */
@media only screen and (max-width: 700px){
    .main_container{
        max-width: 100%;
    }
    .form_container{
        width: 80%;
    }

    .input_error_message{
        color: red;
        text-align: center;
        position: relative;
        font-size: 0.8rem;
    }
}



