.container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    padding: 30px 0px 30px 0px;
}

.image_container{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    gap: 2rem;
}


