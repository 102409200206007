.sidebar_nav{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding: 0.5rem;
    background-color: var(--inactiveColorExtraLight);
    border-right: 0.5px solid var(--inactiveColorMedium);
    box-shadow: 0 0 0.5px 0.5px rgba(0, 0, 0, 0.1);
    position: fixed;
    z-index: 8;
    width: 200px;
    background: var(--glassyBackgroundColor);
    backdrop-filter: blur(20.6px);
    -webkit-backdrop-filter: blur(20.6px);
    border: 1px solid rgba(72, 159, 165, 0.2);
}
.logo{
    width: 150px;
    position: absolute;
    left: 20px;
    top: 40px;
}
.sidebar_top{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 1.5rem;
}

.romp_header{
    font-weight: 900;
    color: var(--defaultColor);
    margin-left: 1.5rem;
    font-size: 24px;
}

.navbar{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 100px;
}

.selected {
    font-weight: bold;
}

.nav_item{
    list-style-type: none;
    margin-left: 1.25rem;
    padding: 0.5rem;
    border-radius: var(--borderRadius);
}

.nav_item:hover{
    background-color: var(--primaryColorExtraLight);
    
}

.nav_item_content_wrapper{
    display: flex;
    align-items: center;
    gap: 1.8rem;
}

.nav_item_content_wrapper:hover, .nav_icon:hover{
    color: black;
    cursor: pointer;

}

.nav_item:hover .nav_icon{
    font-weight: bold;
    color: var(--primaryColor);
}

.nav_icon, .nav_text{
    font-size: 18px;
}

.business_profile_pic{
    width: 8rem;
    height: 8rem;
    object-fit: cover;
}

.sidebar_bottom{
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem;
}

.sidebar_bottom_business_info{
    display: flex;
    align-items: center;
   gap: 1.5rem;
}

.business_acronym{
    border: 0.1rem solid var(--inactiveColorStrong);
    border-radius: var(--borderRadius);
    padding: 1rem;
    cursor: pointer;
    font-size: clamp(1.4rem, 2vw, 1.6rem);
}

.business_text_container{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.business_name {
    color: var(--defaultColor);
    font-size: clamp(1.4rem, 2vw, 1.6rem);
}

.email{
    color: var(--inactiveColorStrong);
    font-size: clamp(1rem, 2vw, 1.2rem);
}