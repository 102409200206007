.multi_button_container{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px; 
    width: 250px;
    text-align: center;
    position: relative;
    border-radius: 4px;
    border-color: #ccd2cf; 
    border-width: 0.05px;
    background-color:  white;
    padding-left: 22px;
    padding-right: 12px;
}

.multi_button_text {
    color: #1F1F1F;
    margin-left: 12px;
}

.icon {
    padding-left: 10px;
    left: 0;
    position: absolute;
    color: #1F1F1F;
    font-size: 2rem;
}


/* //#747775 */
