.community_guidelines_container {
    display: flex;
    flex-direction: column;
    padding: 10rem 5rem 0rem 5rem;
    gap: 3rem;
}
.community_guidelines_header {
    margin-bottom: 5rem;
}

.community_guidelines_container > div > h4{
    font-weight: bold;
}