.login{
    display: flex;
    flex-direction: column;
    gap: 7.5rem;
}

.login_buttons_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.reset_password{
    padding-top: 2rem;
    font-size: 1.6rem;
}

.reset_password:hover {
    color: black;
    text-decoration: underline;
    transition: var(--hover);
}

.login_header {
    color: black;
    margin-bottom: 30px;
}

.google_login_button {
    font-size: 1.8rem;
}

.facebook_icon {
    padding-left: 10px;
    left: 0;
    position: absolute;
    color: white;
    font-size: 36px;
}

.facebook_login_button {
    background-color: #1877F2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px; 
    width: 250px;
    text-align: center;
    position: relative;
    border-radius: 4px;
    border-color: #ccd2cf; 
    border-width: 0.05px;
    padding-left: 32px;
    padding-right: 12px;
    color: white;
}

.facebook_login_button:hover {
    cursor: pointer;
}