.business_home_details_card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--primaryColorLight);
    border-radius: 20px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    background-color: var(--inactiveColorExtraLight);
    width: 1000px;

    /* background: rgba(97, 166, 171, 0.1); */
    background: var(--glassyBackgroundColor);
    /* border-radius: 16px; */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(16.6px);
    -webkit-backdrop-filter: blur(16.6px);
    border: 1px solid rgba(97, 166, 171, 0.16);
}

.card_business_info_top{
    display: flex;
    align-items: center;
}

.business_profile_container{
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.bio_pic{
    border-radius: 1rem;
    object-fit: cover;
    width: 300px;
}

.card_details{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 2rem;
}

.card_details h4{
    font-weight: bold;
}

.card_text_wrapper{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 28rem;

}

.card_details:nth-child(1) .card_text_wrapper p {
    font-size: clamp(1.4rem, 2vw, 2rem);
}
.main_count_container{
    margin-left: 20px;
}
.count_container{
    display: flex;
    justify-content: flex-start;
    gap: 6rem;
    font-weight: bold;
    width: 200%;
    margin-bottom: 5rem;
}

.count {
    font-size: clamp(1.8rem, 3vw, 2.5rem);
    text-align: center;
    color: var(--primaryColorFlip);
}


.home_details_btn{
    padding: 1.5rem;
    border-radius: var(--borderRadiusPillButton);
    width: 25rem;
    align-self: flex-end;
    margin: 2rem;
    font-size: clamp(1.5rem, 2vw, 1.8rem);
    border: none;
    background-color: var(--buttonColorPillDashboard);
    color: white;
    font-weight: 900;
}
.business_name_text{
    margin-top: 20px;
    margin-left: 16px;
}

/* .home_details_btn:hover, .home_details_btn:active {
    background-color: var(--buttonColorPillDashboardExtraLight);
    color: var(--buttonColorPillDashboard);
} */