.navbar{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 10;
}

.navbar_links_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12rem;
    gap: 6rem;
    height: inherit;
    list-style: none;
    color: var(--fontColor);
    z-index: 10;
}

.navbar_link {
    font-size: clamp(2rem, 2.5vw, 2.4rem);
    font-weight: 900;
    list-style: none;
}

.navbar_link > *:hover{
    color: var(--primaryColor);
}


