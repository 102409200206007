.contact_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    padding: 3rem;
    gap: 1rem;
    margin-top: 5rem;
}

.contact_page_logo{
    width: 20rem;
}

.contact_description{
    font-size: 1.8rem;
    padding: 1.5rem;
    text-align: center;
}

/* Dropdown Container Styles */

.contact_dropdown_container{
    display: flex;
    justify-content: space-between;
    width: 40rem;
    text-align: center;
    padding: 1rem;

}

.contact_dropdown_container label{
    font-size: 2.5rem;
}

.contact_dropdown_select{
    font-size: 1.6rem;
    width: 15rem;
    text-align: center;
}

.contact_displayed_email{
    font-size: 2rem;
    color: var(--primaryColorMedium);
    text-align: center; 
    padding: 2rem;
}

/* SUBMIT FORM MESSAGE DISPLAY */
.contact_form_submit_message{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    height: 100vh;
}

.contact_form_submit_message p{
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}


/* FORM STYLING */
.contact_form_wrapper{
    display: flex;
    justify-content: center;
    width: 70rem;

}
.contact_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 2rem;
}

.contact_form_fields{
    display: flex;
    justify-content: space-between;
    width: 97.5%;
    height: 6rem;
}

.contact_form input {
    margin: 1rem;
    padding: 1rem;
    width: 80%;
}
  
.contact_form textarea {
    height: 20rem;
    width: 95%;
    padding: 1rem;
    margin-bottom: 2rem;
    resize: none;
}



form input, textarea {
    border-radius: var(--formInputBorderRadius);
}


@media only screen and (max-width: 800px) {
    .contact_form_wrapper{
        width: 100%;
    }
    .contact_form{
        font-size: 1.6rem;
    }
    .contact_header{
        font-size: 3rem;
    }
}