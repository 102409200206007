.hall_of_fame_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
}

.hof_content_wrapper_center_align{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    height: 100vh;
}

/* CONTENT */
.thank_you_container{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    width: 100%;
    justify-content: center;
    background-color: var(--primaryColor);
}

.thank_you_container > h4{
    font-size: clamp(1.2rem, 2vw, 1.75rem);
    color: white;
    text-align: center;
    width: 100%;
}

.thank_you_name {
    color: white;
    font-size: clamp(2rem, 3vw, 4rem);
}

.thank_you_slideshow_container{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;
}

.halved_array_names_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 90%;
    height: 22.5%;
    opacity: 0.5;
}

/* MAPPED NAMES STYLES */
.mapped_name_container{
    display: flex;
    align-items: center;
    gap: 3rem;
    width: 10rem;
    height: 50%;
}

.mapped_romp_logo{
    width: 2rem;   
}

.mapped_name_text{
  font-size: clamp(0.8rem, 2vw, 1.4rem);
  color: var(--primaryColor);
  margin: 0 auto;
}

.reroute_btn{
    border-radius: var(--borderRadius);
    margin-top: 4rem;
    padding: 1rem;
    border: 5rem;
    border-color: var(--primaryColor);
    background-color: var(--primaryColor); 
    color: var(--primaryColorOffset);
    font-size: 2rem;
    cursor: pointer; 
}

.reroute_btn:hover{
    color: var(--newScreenColor);
    background-color: var(--primaryColor); 
}

.fa_home_btn{
    font-size: 3.5rem;
}

/* Tiny Screens / Phones  (ex: iphone se) < 380 px*/
@media only screen and (max-width: 380px){
    .hof_content_wrapper_center_align{
        gap: 0rem;

    }
    .mapped_name_container{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        text-align: center;
        flex: 1;
    }
    .halved_array_names_wrapper{
        width: 95%;
        gap: 2rem; 
     
    }

    .thank_you_container{
        margin: 1rem;
    }

    .thank_you_slideshow_container{
        gap: 1rem;
        margin-top: 2;
    }
}

/* Very Tiny Screens / Phones  (ex: galaxy fold) < 300 px*/
@media only screen and (max-width: 300px){
    .halved_array_names_wrapper{
        width: 80%;
        gap: 0.8rem;
    }

    .mapped_romp_logo{
        width: 1rem;   
    }
    .mapped_name_text{
        font-size: 0.7rem;
    }
    .thank_you_name{
        font-size: 1.7rem;
    }
}