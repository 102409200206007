.container{
    display: flex;
    margin-top: 20px;
    gap: 2rem;
}

.smaller_screens_layout_top_wrapper, .calendar_container, .times_container{
    flex: 1;
}

.smaller_screens_layout_top_wrapper{
    display: flex;
    gap: 2rem;

}


.month_year_container{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;   
}

.month_year_header{
    font-size: 18px;
    color: var(--primaryColor);
    width: 70%;
    text-align: center;
    font-weight: bold;
}

 .month_year_header_year {
    font-weight: normal;
    color: black;

  }

.toggle_month_button{
    background-color: white;
    border: 0px;
    width: 15%;
    text-align: center;
}

.toggle_month_button:focus{
    outline: none;
}

.icon{
    font-size: 18px;
    color: var(--primaryColor);  
}

.icon:hover{
    color: var(--primaryColorMedium);
}

.table_row_container {
    display: flex;
    justify-content: space-between;
    
}

.table_individual_header{
    width: 75px;
    text-align: center;
    font-size: 14px;
}

.table_individual_cell{
    flex: 1;
}

.table_day_button{
    width: 100%;
    height: 50px;
    padding: 5px;
    background-color: white;
    border: 1px solid white;
    font-size: clamp(1rem, 2vw, 1.4rem);
    border-radius: 5px;
}

.table_day_button_disabled{
    width: 100%;
    height: 50px;
    padding: 5px;
    border: 1px solid white;
    font-size: clamp(1rem, 2vw, 1.4rem);
    border-radius: 5px;
    cursor: default;
    background-color: white;
    color: var(--inactiveColorStrong);
    justify-content: center;
    align-items: center;
    display: flex;
}

.table_day_button:focus{
    background-color: var(--primaryColorLight);
    outline: none;
}

.selected_day_button {
    background-color: var(--primaryColorLight);
}

.selected_day_button:focus {
    background-color: var(--primaryColorLight);
    outline: none;
}

.table_day_button:hover{
    background-color: var(--primaryColorLight);
}


/* Smaller Computer Screens */ 
@media only screen and (max-width: 1000px){
    .container{
        flex-direction: column;
        margin-top: 0;   
        justify-content: center;
        align-items: center;
    }
    .times_container{
        width: 100%;
    }

    .table_day_button, .table_day_button_disabled{
        width: 100%;
        height: 4rem;
        padding: 0.3rem;
    }
}

/* Small Screens / Phones */
@media only screen and (max-width: 700px){
    .container{
        width: 100%;
    }
    
    .smaller_screens_layout_top_wrapper{
        flex-direction: column;
        gap: 2rem;
        width: 100%;
    }
    .calendar_container{
        display: flex;
        flex-direction: column;
    }
    
    .table_individual_header{
        width: 5rem;
    }

    .table_day_button, .table_day_button_disabled{
        height: 2.5rem;
    }
}

/* Very Small Phones (iphone SE, Galaxy Fold, etc. */
@media only screen and (max-width: 340px){
    .table_individual_header{
        width: 3.5rem;
        font-size: 1rem;
    }
}
