.hamburger{
    cursor: pointer;
    position: absolute;
    top: 3rem;
    right: 4rem;
    z-index: 99;
}

.bar {
    display: block;
    width: 3rem;
    height: 0.3rem;
    margin: 0.5rem auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: var(--buttonColorPillDashboard);
    z-index: 99999;
}

.hamburger_active .bar:nth-child(2){
    opacity: 0;
}

.hamburger_active .bar:nth-child(1){
    transform: translateY(0.8rem) rotate(45deg);
}
.hamburger_active .bar:nth-child(3){
    transform: translateY(-0.8rem) rotate(-45deg);
}

/* Mobile Devices up to 480px */
@media (max-width: 480px) {  
    .hamburger{
        top: 2.5rem;
        right: 2.5rem;
    }
}