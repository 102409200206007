.business_account_container{
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.bottom_button_inner_container{
    width: 60%;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

/* Smaller web screens  */
@media only screen and (max-width: 1000px){
    .bottom_button_inner_container{
        margin-bottom: 2rem;
    }
}

/* Mobile Phones */
@media only screen and (max-width: 450px){
    .bottom_button_inner_container{
        width: 75%;
    }
}