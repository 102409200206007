.floating_btn_sign_up{
    position: fixed;
    bottom: 4rem;
    right: 4rem;
    padding: 1rem 2rem;
    border-radius: var(--borderRadiusPillButton);
    background-color: var(--primaryColorBlue) ;
    color: white;
    font-weight: bold;
    font-size: 1.6rem;
    z-index: 2;
    opacity: 0;
    animation: fadeOut 0.5s ease-in-out forwards;
}

.visible {
    animation: fadeIn 0.5s ease-in-out forwards;
}

.floating_btn_sign_up:hover{
    transition: 0.3s ease-in-out;
    background-color: var(--primaryColorExtraBlue); 
    color: white;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}