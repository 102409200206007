.image_container{
  display: flex;
  margin-bottom: 10px;
  background-color: var(--primaryColorLight);
  border-radius: 15px;
  border: 1px solid var(--primaryColorMedium);
  border-bottom-width: 4px;
  z-index: 2;
  margin-left: 5px;
  margin-right: 5px;
  width: 19rem;
  height: 19rem;
}

.image{
  width: 100%;
  border-radius: 15px;
}

.image_container:hover{
  cursor: pointer;
}

.selector{
  width: 100%;
  height: 5px;
  background-color: var(--primaryColor);
  border-radius: 5px;
  z-Index: 1;
}

/* Small Screens / Phones   */
@media only screen and (max-width: 550px){
  .image_container{
      width: 14rem;
      height: 14rem;
  }
}
