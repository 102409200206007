.bordered{
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 14px;
    color: grey;
}
  
.bordered::before,
.bordered::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(50% - 50px);
    height: 2px;
    background-color: lightgrey;
}

.bordered::before {
    left: 0;
}
  
.bordered::after {
    right: 0;
}