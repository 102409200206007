.container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}  
.header {
    font-size: 16px;
    color: var(--inactiveColorExtraStrong);
} 
.inputContainer {
    position: relative; /* Added to position the icon relative to this container */
    width: 100%;
}  
.input {
    height: 50px;
    border-radius: 10px;
    border: 1px solid var(--primaryColorLight);
    width: 100%;
    padding-left: 15px;
    font-size: 16px;
}
.input:focus {
    border-bottom: 2px solid var(--primaryColorMedium);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    outline: none; /* Remove the default outline on focus */
}  
.headerActive {
    color: var(--primaryColorMedium);
    font-weight: bold;
}