.footer_mobile_overflow_x_hidden{
    overflow-x: hidden;    
}

.footer{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5rem 0;
    background-color: var(--defaultColor);
}

.btn_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--contentGap);
}

.btn_header{
    color: white;
}

.footer_menu_container{
    display: flex;
    justify-content: space-around;
    padding: 5rem 0;
}

.footer_menu_container > div > div > p{
    color: var(--inactiveColorMedium);
}

.footer_menu_container > div > div > p > a {
    color: var(--inactiveColorMedium);
    cursor: pointer;
    text-decoration: none;
}

.footer_menu_container > div > div > p > a:hover {
    color: white;
}

.footerSectionTitle{
    padding-bottom: 2rem;
    font-weight: bold;
}

.footerRomp {
    margin-top: 2rem;
}

/* Mobile Devices (landscape) */
@media (max-width: 775px) {
    .footer{
        display: flex;
        flex-direction: column;

    }
    .footer > div > div > p,
    .footer > div > div > p > a {
        font-size: 1.8rem;
    }

    .btn_container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4rem;
        padding: 0 4rem;
    }

    .footerSectionTitle{
        padding-bottom: 1rem;
    }

    .footerRomp{
        text-align: left;
        padding: 0 4rem;
    }

    .footer_menu_container{
        flex-direction: column;
    }

}


