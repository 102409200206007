.overview{
    display: flex;
    flex-direction: column;
}

.overview_section, .overview_section_reverse{
    margin-top: -1px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--contentGap);
}

.overview_section_reverse{
   flex-direction: row-reverse;
   background-color: var(--defaultColor);
   padding: 4rem 0;
}

.text_container, .text_container_reverse {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 0.8;
    /* text-align: center; */
}

.text_container_reverse > * {
    color: var(--backgroundColor);
}

.animation_one_container {
    max-width: 45rem;
}

.animation_two_container {
    max-width: 45rem;
}

.subheader {
    font-size: clamp(2rem, 2.4vw, 2.5rem);
}

/* Tablets (portrait) */
@media (max-width: 950px) { 
    .overview_section, .overview_section:nth-child(2), .overview_section_reverse{
        flex-direction: column-reverse;
        gap: 1rem;
    }

    .text_container, .text_container_reverse {
        padding: 0 2.4rem;
    }

    .text_container{
        margin-top: 5rem;
    }

    .animation_one_container {
        margin-top: -5rem;
        max-width: 35rem;
    }

    .animation_two_container {
        max-width: 35rem;
        margin-top: 3rem;
    }
}

/* Mobile Devices up to 480px */
@media (max-width: 480px) { 
    .text_container, .text_container_reverse {
        width: 100vw;
        padding: 2rem 3rem;
    }
    .animation_one_container{
        margin-top: -6rem;
    }
    .animation_two_container{
        margin-top: 3rem;
    }
}

/* Tiny Mobile Devices up to 325px (e.g. galaxy fold) */
@media (max-width: 325px) {  
    .animation_one_container {
        max-width: 25rem;
    }
    .animation_two_container{
        max-width: 25rem;
    }
}
