.container {
  padding: 0.5rem;
  align-items: center;
  border-radius: var(--borderRadius);
  background-color: var(--primaryColorLight);
  margin: 0.5rem;
}

.text_label {
  font-weight: bold;
  color: var(--primaryColor);
  font-size: clamp(1.2rem, 3vw, 1.6rem);
}