.activity_detail_footer_container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 3rem;
    background-color: var(--primaryColor);
    position: sticky;
    bottom: 0;
    padding-bottom: 4rem;
}

.apps_button_container{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 2rem;
}

.apps_button_start_container{
    display: flex;
    width: 50%;
    justify-content: flex-start;
}

.apps_button_end_container{
    display: flex;
    width: 50%;
    justify-content: flex-end;
}

.apps_button{
    height: 4rem;
    /* width: 25%; */
    min-width: 125px;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 50px;
    border-color: var(--primaryColor);
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app_button_text{
    color: var(--defaultColor);
    font-weight: bold;
    font-size: 1.5rem;
}

.app_button_logo{
    color: var(--defaultColor);
    font-weight: bold;
    font-size: 1.75rem;
    margin-right: 5px;
}

.activity_detail_footer_container p:nth-child(3){
    margin-top: 1rem;
}

.website_link:hover, .website_link_icon:hover{
    color: white;
    font-weight: bold;
}

.website_link_icon{
    margin-bottom: 1rem;
    font-size: 1.2rem;

}

.activity_detail_footer_header{
    color: white;
    font-weight: bold;
    font-size: 2.25rem;
}

.activity_detail_footer_description{
    color: white;
    font-size: 1.5rem;
}

/* Phones   */
@media only screen and (max-width: 550px){
    .activity_detail_footer_container{
        gap: 1.5rem;
    }
    .appsButton{
        height: 4rem;
        width: 12rem;
    }
}