.image_container{
  display: flex;
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
  z-index: 2;
  margin-left: 5px;
  margin-right: 5px;
  
}

.image{
  border-radius: 15px;
}

.image_container:hover{
  cursor: pointer;
}

.selector{
  width: 100%;
  height: 5px;
  background-color: var(--primaryColor);
  border-radius: 5px;
  z-Index: 1;
  
}
