.relative_container{
    position: relative;
}

.share_activity_warning_container{
    display: flex;
    justify-content: center;
    padding-bottom: 3rem;
}

.share_activity_warning_container p{
    color: var(--defaultColor);
    font-weight: 900;
    background-color: white;
    padding: 1rem;
    text-align: center;
}

.activity_detail_section{
    margin-bottom: 1rem;
}

.empty_activity_detail_section{
    min-height: 100vh;
}

.activity_detail_container{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 600px;
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
    border-style: solid;
    border-radius: 10px;
    border-color: var(--primaryColorMedium);
    border-width: 2px;
}

.overview_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.activity_name {
    text-align: center;
    margin-bottom: 1rem;
}

.location_icon_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primaryColorLight);
    height: 7rem;
    width: 7rem;
    border-radius: 1rem;
    border-color: var(--primaryColorMedium);
    border-top-width: 0.5px;
    border-left-width: 0.5px;
    border-right-width: 0.5px;
    border-bottom-width: 0.4rem;
    border-style: solid;
}

.location_icon {
    font-size: 5rem;
    color: var(--primaryColor);
}

.location_name_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header_text {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--primaryColor);
}

.subheader_text {
    font-style: italic;
    color: var(--defaultColor);
    font-size: 1.4rem;
}

.items_list_container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin: 1rem 0rem; 
}

.item_container {
    display: flex;
    flex-direction: column;
}

.item_header_text {
    color: var(--defaultColor);
}

.item_header_spacer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.item_container_break {
    width: 0.1rem;
    background-color: var(--primaryColor);
}

.item_label_text {
    font-size: 1.4rem;
    color: var(--primaryColor);
    font-weight: bold;
}


.activity_img {
    width: 100%;
    border-radius: 1rem;
    object-fit: cover;
}

.description_container {
    padding: 0rem 1rem 1rem 1rem;    
}

.description_text {
    color: var(--defaultColor);
    font-size: 1.4rem;
}

.unpressable_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.content_creation_container{
    display: flex;
    flex-direction: column;
    margin: 2rem 1rem 0rem 1rem;
    gap: 1rem;
}

.content_creation_header{
    font-weight: bold;
    font-size: 2rem;
}

.profile_img_wrapper {
    display: flex;
    flex-direction: row;
}

.profile_img {
    border-radius: 50px;
    width: 6rem;
    height: 6rem;
}

.creator_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1rem;
}

.creator_name {
    font-size: 2rem;
    font-weight: bold;
    color: var(--defaultColor);
}

.creator_location {
    font-size: 1.6rem;
    font-style: italic;
    color: var(--defaultColor);
}

/* Smaller Web Browsers   */
@media only screen and (max-width: 900px){
    .activity_detail_section{
        margin: 0rem;
        padding: 0rem 0rem;
    }
    .activity_detail_container{
        padding-top: 0rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border-style: none;
    }
}
