.login_with_email{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.sign_in_form_container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.forgot_password_button{
    border: none;
    background-color: white;
    width: 250px;
    justify-content: flex-start;
    margin-bottom: 32px;
}

.forgot_password_button_text{
    color: grey;
    text-align: start;
    padding-left: 10px;
    font-size: 14px;
}