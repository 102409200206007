.main_container{
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    padding: 30px 0px 30px 0px;
    min-height: 500px;
}

.form_container{
    margin-top: 50px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.input_error_message{
    color: red;
    position: absolute;
}

/* Small Screens / Phones   */
@media only screen and (max-width: 550px){
    .input_error_message{
        color: red;
        text-align: center;
        position: relative;
        font-size: 0.8rem;
    }
}


