.main_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    padding: 3rem 0;
    min-height: 500px;
    
}

.form_container{
    margin-top: 2rem;
    width: 60%;
}

.pressable_container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    
}

/* Smaller web screens */
@media only screen and (max-width: 1300px){
    .form_container{
        width: 100%;
    }
    .pressable_container{
        display: flex;
    }
}

/* Even smaller web screens */
@media only screen and (max-width: 820px){
    .pressable_container{
        flex-direction: column;
    }
}





