.hall_of_fame_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hof_content_wrapper_center_align{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    margin-top: 6rem;
    height: 100vh;
}

/* CONTENT */

.thank_you_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem;
    width: 85%;
    justify-content: center;
    background-color: var(--primaryColor);
}

.thank_you_container > h4{
    font-size: clamp(1.4rem, 2vw, 1.75rem);
    color: white;
}

.thank_you_text {
    text-align: center;
}

.thank_you_name {
    color: white;
    font-size: clamp(2rem, 3vw, 4rem);
    margin-right: 20px;
}

.thank_you_slideshow_container{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;
}

.halved_array_names_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4rem;
    width: 85%;
    opacity: 0.5;
}

/* MAPPED NAMES STYLES */
.mapped_name_container{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.mapped_romp_logo{
    width: 2rem;   
}

.mapped_name_text{
  font-size: clamp(0.8rem, 2vw, 1.4rem);
  color: var(--primaryColor);
  margin: 0 auto;
}


/* Smaller browser screens < 880 px */
@media only screen and (max-width: 880px){
    .hof_content_wrapper_center_align{
        gap: 1rem;
    }
    .mapped_name_container{
        display: flex;
        flex-direction: column;
        gap: 0.1rem;
    }
    .halved_array_names_wrapper{
       gap: 2rem;   
    }
}

/* Small Screens / Phones */
@media only screen and (max-width: 550px){
    .hof_content_wrapper_center_align{
        gap: 3rem;
        margin-top: 14rem;
    }
    .mapped_name_container{
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
    }
    .halved_array_names_wrapper{
        justify-content: space-evenly;
        gap: 1.4rem; 
    }

    .thank_you_container{
        margin: 1rem;
    }

    .thank_you_slideshow_container{
        gap: 1rem;
        margin-top: 2;
    }
}




