.container {
  margin-top: 20px;
}  
.header {
  font-size: 16px;
  color: var(--inactiveColorExtraStrong);
} 
.inputContainer {
  position: relative; /* Added to position the icon relative to this container */
  width: 100%;
}  
.placeholderNumberText{
  font-size: 16px;
}
.phoneInput {
  display: flex; /* Added to make "+1" and input inline */
  align-items: center; /* Added to vertically center "+1" and input */
}  
.input {
  height: 40px;
  border-radius: 10px;
  border: 1px solid var(--primaryColorLight);
  width: 100%;
  padding-left: 16px;
  font-size: 16px;
}
.input:focus {
  border-bottom: 2px solid var(--primaryColorMedium);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  outline: none; /* Remove the default outline on focus */
}  
.headerActive {
  color: var(--primaryColorMedium);
  font-weight: bold;
}