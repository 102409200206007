.container{
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    padding: 3rem 0px 3rem 0px;
    min-height: 50rem;

}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
    flex: 1;
  }
  

  
  
  