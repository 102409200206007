.business_confirm_email{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    margin-top: 15rem;
}

.business_confirm_email p {
    text-align: center;
}

.email{
    color: var(--primaryColor);
    font-weight: bold;
}


