.verify_page_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align:center; 
    height: 100vh;
    background: var(--backgroundColor);
}

.verify_page_wrapper{
    text-align: center;
    margin-top: 10rem; /* no margin top on mobile */
}

.verify_page_logo{
    width: 12.5rem;
    margin: 2rem;
}

.verify_header{
    font-size: 3rem;
}

.verify_description{
    margin-top: 1.5rem;
    font-size: 2rem;
    text-align: center; 
}

.message_success{
    font-size: 1.5rem;
    color: var(--successColor); 
    font-weight: bold;
    text-align: center;
    margin-top: 5%; /* mobile is 15% */
}

.message_fail{
    font-size: 1.5rem;
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 5%; /* mobile is 15% */
}

.countdown_text{
    margin-top: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
}

.countdown{
    color: var(--primaryColor);
    font-weight: bold;
    font-size: 2rem;
    margin: 2rem 0;
}
