.container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
}

.spinner {
width: 10rem;
height: 10rem;
border-radius: 50%;
border: 4px solid rgba(0, 0, 0, 0.1);
border-top-color: var(--primaryColor);
animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
}