.business_home{
    display: flex;
    gap: 5.5rem;
}

.business_sidebar{
}

.business_dashboard{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    gap: 30px;
    margin-left: 150px;
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    flex: 1.5;
    margin-bottom: 40px;
}

.business_dashboard h3 {
    font-weight: bold;
}

.business_dashboard_top{
    display: flex;
    align-items: center;
    gap: 3rem;
}
