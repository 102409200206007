.password_page_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align:center; 
    height: 100vh;
    background: var(--backgroundColor);
}

.password_page_wrapper{
    margin-top: 10rem;
}

.title_container{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.line {
    width: 0.15rem;
    height: 10rem; 
    background-color: var(--inactiveColorStrong); 
}

.logo{
    width: 15rem;
    height: auto;
}

.password_form_container{
    margin-top: 2.5rem;

}

.password_form{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 32px;
}

.submit_email_wrapper{
    margin-top: 32px;
    margin-bottom: 16px;
}

.message_success{
    margin: 2.5rem;
    color: var(--successColor); 
    font-weight: bold;
}

.message_fail{
    margin: 2.5rem;
    color: red;
    font-weight: bold;
}


/* Small Screens / Phones  */
@media only screen and (max-width: 550px){
    .password_page_container{
        height: 90vh;
    }

     .password_page_logo{
        margin: 2rem 0;
        width: 30%;
    }

    .password_page_wrapper > h3{
        margin-top: 10%;
    }
    
    .password_page_wrapper > h4 {
        display: block;
        margin-top: 5%;
    }

    .password_form_container{
        padding: 3.5rem 0rem;
    }

    .message_success, .message_fail{
        margin: 1rem;
    }
}