.signup_container{
    border: 0.1rem solid #ccc;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 35rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5rem;
    padding: 3rem 5rem;
}

.form_container{
    margin-top: 2rem;
    width: 100%;
}

.header{
    font-weight: 900;
    text-align: center;
    font-size: 6rem;
}

.subheader{
    margin-top: 1rem;
    text-align: center;
    color: grey;
    font-weight: bold;
    font-size: 1.8rem;
}

.forgot_password_container{
    margin-top: 2rem;
    text-align: center;
}

.secondary_container{
    border: 0.1rem solid #ccc;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 35rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    padding: 3rem 5rem;
}

.secondary_text{
    color: black;
    font-size: 1.4rem;
}

.pressable_text{
    font-weight: bold;
    color: var(--primaryColor);
    cursor: pointer;
}

.pressable_text:hover{
    color: var(--primaryColorMedium);
}

.error_message {
    text-align: center;
    margin: 0.25rem 0;
    font-size: clamp(1.2rem, 2vw, 1.6rem);
    color: red;
}