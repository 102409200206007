.modal_container{
    top: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
}

.modal_background{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.5);
    cursor: pointer;
}

.close_btn_container{
    position: relative;
    left: 64%;
    bottom: 6rem;
    background-color: white;
    border: 0.1rem solid lightgrey;
    border-radius: 50%;
}

.close_btn{
    height: 3rem;
    width: 3rem;
    color: var(--primaryColorLight);
    cursor: pointer;
}

.close_btn:hover{
    transition: 0.2s ease-in-out;
    color: var(--primaryColorMedium);
}

.modal_flex_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    max-width: 45rem;
    padding: 5rem;
    z-index: 999;
    background-color: var(--backgroundColor);
    border: 0.1rem solid lightgrey;
    border-radius: var(--borderRadius);
}

.modal_content_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

}

.modal_header{
    text-align: center;
}
