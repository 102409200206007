.verify_page_container{
    display: flex;
    flex-direction: column;
    padding: 2rem; /* mobile is 8rem 2rem 2rem 2rem */
    height: 100vh;
}

.verify_page_wrapper{
    text-align: center;
    margin-top: 10rem; /* no margin top on mobile */
}

.verify_page_logo{
    width: 12.5rem;
    margin: 2rem;
}

.verify_header{
    color: var(--primaryColor);
    font-size: 3rem;
}

.verify_description{
    margin-top: 1.5rem;
    font-size: 2rem;
    color: var(--primaryColorMedium);
    text-align: center; 
}

.message_success{
    font-size: 1.5rem;
    color: var(--successColor); 
    font-weight: bold;
    text-align: center;
    margin-top: 5%; /* mobile is 15% */
}

.message_fail{
    font-size: 1.5rem;
    color: red;
    font-weight: bold;
    text-align: center;
    margin-top: 5%; /* mobile is 15% */
}

.countdown_text{
    margin-top: 2rem;
    font-size: 1.5rem;
    color: var(--primaryColorMedium);
    font-weight: bold;
}

.countdown{
    color: var(--primaryColor);
    font-weight: bold;
    font-size: 2rem;
    margin: 2rem 0;
}

.reroute_btn{
    width: 70%;
    max-width: 35rem;
    border-radius: 5px;
    margin-top: 1.5rem;
    padding: 1rem;
    border: 5rem;
    border-color: var(--primaryColor);
    min-width: 2.5rem;
    background-color: var(--primaryColorMedium); 
    color: var(--primaryColorOffset);
    font-size: 2rem;
    cursor: pointer; 

}

.reroute_btn:hover{
    font-weight: bold;
    background-color: var(--primaryColor); 
}