.container{
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    padding: 3rem 0px 3rem 0px;
    min-height: 50rem;
}

.form_container{
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 60%;
  margin-left: auto;
  margin-right: auto;

}

.business_name{
  text-align: center;
  padding: 2rem 0;
}

.dropdown_container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5rem;
  align-self: center;
  margin-top: 5rem;
  font-size: clamp(1.5rem, 2vw, 2rem);
}

.dropdown_container select{
  padding: 1rem;
  border: none;
  background-color: var(--inactiveColorLight);
  border-radius: var(--borderRadius);
}

.dropdown_container select:focus{
  outline: none;
}


.error_message{
  margin-top: 2rem;
  text-align: center;
  color: red;
}

.input_error_message{
  color: red;
  position: relative;
}


/* Small Screens / Phones   */
@media only screen and (max-width: 700px){
  .container{
      max-width: 100%;
  }
  .form_container{
      width: 80%;
  }
  .dropdown_container{
    gap: 1rem;
    
  }

  .input_error_message{
      color: red;
      text-align: center;
      font-size: 0.8rem;
  }
}

/* Tiny Screens / Small Phones   */
@media only screen and (max-width: 420px){
  .dropdown_container{
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.5rem;
  }
}
