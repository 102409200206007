.overflow_x_hidden_container{
    overflow: hidden;
    position: relative;
}

.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8rem;
}

/* Mobile Devices up to 480px */
@media (max-width: 480px) {  
    .home {
        gap: 5rem;
    }
  }