.upcoming_activities {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/*TODO: change width/height when content is established via Recoil -- remove fixed height */
.upcoming_activity > div, .upcoming_activity > a{
  width: 326px;
  height: 326px;
  border-radius: 10px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: var(--inactiveColorExtraLight);
  border: 1px solid var(--primaryColorLight); */
  background: var(--glassyBackgroundColor);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20.6px);
  -webkit-backdrop-filter: blur(20.6px);
  border: 1px solid rgba(97, 166, 171, 0.2);
}

.upcoming_activity > div:hover, .upcoming_activity > a:hover{
  cursor: pointer;
  outline: 0.3rem solid var(--primaryColorMedium);
}
.activity_image{
  width: 326px;
  height: 326px;
  border-radius: 10px;
  background: var(--glassyBackgroundColor);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20.6px);
  -webkit-backdrop-filter: blur(20.6px);
  border: 1px solid rgba(97, 166, 171, 0.2);
}