.settings{
    text-align: right;
    padding-bottom: 2rem;
}

.settings li {
    padding: 1rem 0;
    list-style-type: none;
}

.settings li:hover{
    background-color: var(--primaryColorExtraLight);
    color: black;
    cursor: pointer;
}

.settings li a {
    font-size: clamp(1.6rem, 2vw, 1.8rem);
}

.settings li a:hover{
    color: black;
}

