.question_icon_container{
    position: fixed;
    right: 2rem;
    bottom: 7rem;
    width: 15rem;
    height: 13rem;
    z-index: 10;

}

.question_icon {
    position: fixed;
    bottom: 0.5rem;
    right: 2rem;
    cursor: pointer;
    fill: var(--primaryColorMedium);
    font-size: 4rem;
}

.question_icon:hover{
    fill: var(--primaryColor);
}

.toggled_options_container{
    display: flex;
    flex-direction: column;
    gap: 2rem;
/*     background-color: var(--primaryColorLight); */
    background-color: white;
    padding: 1rem;
    border: 0.15rem solid var(--primaryColor);
    border-radius: var(--borderRadius);
 
    
}

.toggled_options_container a{
    text-align: right;
    font-size: clamp(1.4rem, 2vw, 1.6rem);
    padding: 0.1rem;
}

.toggled_options_container a:hover {
    color: var(--textColor);
    font-weight: bold;
}