.edit_profile_content{
    display: flex;
    flex-direction: column;
    width: 1000px;
    margin-left: 240px;
}
.profile_title_container{
    min-width: 350px;
    max-width: 350px;
    margin-right: 50px;
}
.profile_title{
    font-weight: bold;
    color: var(--defaultColor);
    font-size: 18px;
}
.profile_title_sub{
    margin-top: 10px;
    color: var(--inactiveColorExtraStrong);
    font-size: 18px;
}
.profile_info_wrapper{
    display: flex; 
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 0.5px solid var(--primaryColorLight);
}
.profile_descriptor{
    color: var(--defaultColor);
    font-size: 18px;
}
.profile_edit_descriptor_button {
    font-size: 18px;
    margin-top: 10px;
    cursor: pointer;
    color: var(--primaryColorBlue);
}
.profile_edit_descriptor_button:hover {
    text-decoration: underline;
}
.arrow_icon{
    margin-left: 5px;
    margin-bottom: 2px;
    font-size: 18px;
    cursor: pointer;
    color: var(--buttonColorPillDashboard);
}
.profile_picture{
    border-radius: 1rem;
    object-fit: cover;
    width: 200px;
}