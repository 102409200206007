.container {
    display: flex;
    flex-direction: column;
    padding: 10rem 5rem 5rem 5rem;
    gap: 3rem;
}

.container > div > h4{
    font-weight: bold;
}

.relative_container{
    position: relative;
}


