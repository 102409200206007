.container{
    display: flex;
    justify-content: center;
    width: 25vh;
    height: 25vh;
    margin-bottom: 10px;
    
}

.container:not(:first-child),
.container:not(:last-child){
    margin-left: 5px;
    margin-right: 5px;
}

.inner_container_active{
    border: 1px solid var(--primaryColorMedium);
    border-radius: 15px;
    width: 25rem;
    padding: 20px;
    background-color: var(--primaryColorLight);
    border-bottom-width: 4px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
}

.inner_container_inactive{
    border: 1px solid var(--inactiveColorStrong);
    border-radius: 15px;
    width: 25rem;
    padding: 20px;
    border-bottom-width: 4px;
}

.inner_container_inactive:hover{
    background-color: var(--inactiveColorLight);
    cursor: pointer;
}

.inner_container_active:hover{
    cursor: pointer;
}

.icon{
    width: 100%;
    font-size: 7rem;
    color: var(--primaryColor);
    text-align: center;
}

.header_text{
    width: 100%;
    font-size: clamp(1.3rem, 2vw, 1.4rem);
    color: black;
    font-weight: bold;
    text-align: center;
    /* margin-top: 30px; */
}

.subheader_text{
    font-size: 1.4rem;
    color: var(--inactiveColorStrong);
    text-align: center;
}

/* Even smaller web screens */
@media only screen and (max-width: 820px){
    .container{
       /*  width: 100vw; */
        /* height: 15vh; */
        width: 100%;
        height: 75%;  
    }
}



/* Mobile Screens */
@media only screen and (max-width: 450px){
    .inner_container_active, .inner_container_inactive{
        justify-content: center;
        align-items: center;
    }

    .header_text{
        margin-top: 1.5rem;
    }
}