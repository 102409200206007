.title_container{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.line {
    width: 0.15rem;
    height: 10rem; 
    background-color: var(--inactiveColorStrong); 
}

.logo{
    width: 15rem;
    height: auto;
}

/* Mobile Devices up to 480px */
@media (max-width: 480px) { 
    .title_container{
        gap: 1.5rem;
    }

    .line {
        height: 6rem; 
    }

    .title_container h2 {
        font-size: 3rem;
    }

    .logo{
        width: 8.5rem;
    }
}

/* Tiny Mobile Devices up to 325px (e.g. galaxy fold) */
@media (max-width: 325px) {  
}