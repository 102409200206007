.our_team_page_container{
    display: flex;
    flex-direction: column;   
    padding: 10rem 10rem;
}

.our_team_members_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.our_team_page_container h2{
    align-self: center;
    margin: 2rem 0rem;
    text-align: center; /* center for tiny screens < 300 px (e.g. galaxy fold) */
}

/* COLUMN - Small Screens / Phones   */
@media only screen and (max-width: 1230px){
    .our_team_members_container{
        gap: 2rem;
    }
}

/* Full Width Bio Pics - Smaller Screens / Phones   */
@media only screen and (max-width: 700px){
    .our_team_page_container{
        padding: 10rem 0rem;
    }
}