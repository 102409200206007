.full_width_button_container{
    margin-top: 2rem;
}

.full_width_button{
    width: 100%;
    border-radius: 1rem;
    border: 0.1rem solid var(--primaryColorMedium);
    background-color: var(--primaryColorMedium);
    color: white;
    font-weight: 900;
    font-size: 1.6rem;
    padding: 1.5rem;
}

.full_width_button:hover{
    width: 100%;
    border-radius: 1rem;
    border: 0.1rem solid var(--primaryColor);
    background-color: var(--primaryColor);
    color: white;
    font-weight: 900;
    font-size: 1.6rem;
}

.full_width_button:focus{
    outline: none;
}

.full_width_button_faded{
    background-color:  var(--inactiveColorMedium);
    border: none;
    color: var(--fontColor);
}

.full_width_button_faded:hover{
    background-color: var(--inactiveColorStrong);
    border: none;
}

.full_width_button_disabled{
    width: 100%;
    border-radius: 1rem;
    border: 0px solid var(--inactiveColorStrong);
    background-color: var(--inactiveColorMedium);
    color: var(--inactiveColorStrong);
    font-weight: 900;
    font-size: 1.6rem;
    padding: 1.5rem;
}

