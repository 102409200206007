:root {
    /* primary & default colors */
    --primaryColor: #561a4c;
    --primaryColorMedium: #895f82;
    --primaryColorLight: #e6dde4;
    --primaryColorExtraLight: rgb(230, 221, 228, 0.7);
    --primaryColorFlip: #1A5624;
    --primaryColorFlipMedium: #5f8966;
    --primaryColorFlipLight: #baccbd;
    --backgroundColor: white;
    --defaultColor: #1f1f30;
    --primaryColorExtraBlue: #4d9298;
    --primaryColorBlue: #61A6AB;
    --primaryColorBlueMedium: #90c1c4;
    --primaryColorBlueLightish: #bbd9db;
    --primaryColorBlueLight: #e7f2f2;
    --primaryColorOrange: #ED5B2D;


    --fontColor: #546A7B;
    --fontColorLightBlack: rgba(31, 31, 48, 0.85);
    --buttonColorPillDashboard: #61A6AB;
    --buttonColorPillDashboardExtraLight: rgb(97,166,171, 0.1);

    /* action colors */
    --negativeColor: #913156;
    --successColor: #00AC7F;
    --warningColor: #E98653;
    --disabledColor: grey;
    --primaryColorOffset: white;
    /* new screen */
    --newScreenColor: #FFC800;
    --newScreenColorLight: #ffe480;

    /* inactive colors */ 
    --inactiveColorExtraLight: #F8F8F8;
    --inactiveColorLight: #F1F1F1;
    --inactiveColorMedium: #E8E8E8;
    --inactiveColorStrong: #B1B1B1;
    --inactiveColorExtraStrong: #565674;

    /* home page sections background colors */
    --conceptBackgroundColor: #E5DCE4;

    /* border styles */
    --borderRadius: 0.5rem;
    --borderRadiusPillButton: 10rem;
    
    /* form styles */
    --formInputBorderRadius: 0.5rem;

    /* wave styles */
    --waveHeight: 250;

    /* business dashboard glassmorphism */
    --glassyBackgroundColor: linear-gradient(
        to right bottom,
        rgba(97, 166, 171, 0.15),
        rgba(97, 166, 171, 0.05)
    );

    --glassyBackgroundColorLightPurple: linear-gradient(
        to right bottom,
        rgba(230, 221, 228, 0.1),
        rgba(230, 221, 228, 0.05)
    );
    
    /* gaps */
    --contentGap: 10rem;
    --contentGapMobile: 2.5rem;
    --defaultGap: 2rem;
    --formGap: 2rem;

    /* hover */
    --hover: all 0.5s ease-in-out;

}