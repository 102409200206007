.manage_community{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;
}

.text_container{
    text-align: center;
}

.text_container p {
    font-size: clamp(2rem, 2.4vw, 2.5rem);
}


.pillars_container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8rem;
}

.pillars_text{
    color: var(--defaultColor);
}

.pillar_content{
    flex: 1 1 20rem; 
    max-width: 40rem; 
    text-align: center;
}

.pillars_container h6 {
    color: var(--defaultColor);
    font-weight: bold;
}

.img{
    width: 100%;
    height: 20rem;
    object-fit: cover;
    margin-bottom: 2rem;
}

/* Tablets (landscape) / Small Laptops) */
@media (max-width: 1200px) {  
    .img{
        margin-bottom: 0.5rem;
    }

    .manage_community{
        gap: var(--contentGapMobile);
    }
}

/* Tablets (portrait) */
@media (max-width: 950px){
    .manage_community{
        align-items: flex-start;
        padding: 0 3rem;
    }

    .text_container{
        text-align: left;
    }

    .pillars_container{
        gap: 2rem;
    }

    .pillar_content{
        width: 100%;
        text-align: left;
    }

    .img {
        width: 80%;
        height: 20rem;
    }
}

/* Mobile Devices up to 480px */
@media (max-width: 480px) {  
    .pillars_container{
        gap: 5rem;
    }
/*     .pillar_content{
        width: 100%;
        text-align: left;
    } */
 
}