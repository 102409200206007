
.legal_text{
    font-size: 12px;
    margin-top: 20px;
    color: grey
}

.pressable_text{
    font-weight: bold;
    color: var(--primaryColor);
    cursor: pointer;
}

.pressable_text:hover{
    color: var(--primaryColorMedium);
}