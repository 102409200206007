.event{
    width: 100%;
    height: 100%;
    position: relative;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--borderRadius);
}

.gradient_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--borderRadius);
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 25%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.6) 75%,
      rgba(0, 0, 0, 0.7) 100%
    );
}

.placeholder {
    display: grid;
    place-items: center;
    width: 18.5rem;
    height: 24rem;
    background-color: var(--inactiveColorMedium);
    border-radius: var(--borderRadius);
}

.logo {
    filter: grayscale(70%);
    width: 40%;  
}



.event:hover{
    transform: scale(1.05);
    transition: transform 0.45s ease-in-out;
  }

.event_name{
    position: absolute;
    width: 100%;
    color: var(--backgroundColor);
    font-weight: 900;
    padding: 1rem;
    font-size: clamp(0.65rem, 2vw, 1.45rem);
    bottom: 0rem;
    left: 0rem;
    overflow-wrap: break-word;
}

/* Mobile Devices (landscape) */
@media (max-width: 775px) { 
/*     .event{
        width: 14.5rem;
        height: 22.5rem;
    } */
}

/* Mobile Devices up to 480px */
@media (max-width: 480px) {  

    .event_name{
        width: 100%;
    }
}


