.label_container{
    position: relative;
    margin-top: 0.5rem;
    width: 100%;
    
}

.individual_form_container{
    display: flex;
    flex-direction: row;
    margin: 0.5rem 0;
    
}


.individual_form{ 
    width: 100%;
    padding: 1rem;
    
    border: 1px solid lightgray;
    font-size: 1.2rem;
    font-weight: bold;
    color: black;
    background-color: var(--inactiveColorLight);
    border-radius: 1rem;
    resize: none;
    
}

.individual_form:focus{ 
    outline: none;
    border: 1px solid grey;
}

.individual_form_error{
    width: 100%;
    padding: 1rem;
    border: 1px solid red;
    font-size: 1.2rem;
    font-weight: bold;
    color: black;
    background-color: var(--inactiveColorLight);
    border-radius: 1rem;
    resize: none;
}

.individual_form_error:focus{
    outline: none;
    border: 1px solid red;
}

.individual_form_overlay{
    position: absolute;
    left: 0.5rem;
    background: #fff;
    padding: 0 0.5rem;
}

.individual_form_overlay_regular_placement{
    position: absolute;
    left: 0.5rem;
    padding: 0 0.5rem;
    top: 1rem;
}

.individual_form_overlay_top_placement{
    position: absolute;
    left: 0.5rem;
    background: #fff;
    padding: 0 0.5rem;
    top: -8.0.5rem;
    color: grey;
    border-radius: 1rem;
}


.show_password_icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.6rem;
    color: var(--primaryColor)
}

.show_password_icon:hover{
    color: var(--primaryColorMedium)
}