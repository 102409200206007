.input {
    height: 4rem;
    border-radius: 1rem;
    border: 0.1rem solid var(--primaryColorLight);
    width: 25rem;
    padding-left: 1.6rem;
    font-size: 1.6rem;
}

.input:focus {
    border-bottom: 2px solid var(--primaryColorMedium);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    outline: none; 
  }  