.notfound_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    gap: 4rem;
    padding: 2rem;
}

.notfound_links_wrapper{
    display: flex;
    justify-content: center;
    gap: 5rem;
}

.notfound_link{
    margin-top: 2rem;
    font-size: clamp(5rem, 6vw, 10rem);
    color: var(--primaryColor);
}

.notfound_link:hover{
    color: var(--primaryColorFlip);
}

.subheader_text{
    margin-top: 3rem;
}