.logged_in_landing {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--defaultGap);
}

.landing_description {
    margin: 32px;
    text-align: center;
}