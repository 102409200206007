.activities{
    width: 1000px;
}

.activities_headers{
    display: flex;
    gap: 3rem;    
    margin-bottom: 3rem;
}

.activities_headers h4{
    cursor: pointer;
    color: var(--inactiveColorStrong);
}

.active{
    color: var(--primaryColorMedium) !important;
    border-bottom: 0.3rem solid var(--primaryColorMedium);
}