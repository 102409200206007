.container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}  
.header {
    font-size: 16px;
    color: var(--inactiveColorExtraStrong);
} 
.inputContainer {
    position: relative; /* Added to position the icon relative to this container */
    width: 100%;
}  
.iconContainer {
    position: absolute;
    padding-top: 5px;
    top: 50%; /* Updated to vertically center the icon */
    left: 20px; /* Updated to set the desired left margin */
    transform: translateY(-50%); /* Added to vertically center the icon */
}
.icon{
    margin-right: 10px;
    top: 50%; /* Updated to vertically center the icon */
}
.placeholderNumberText{
    font-size: 16px;
}
.phoneInput {
    display: flex; /* Added to make "+1" and input inline */
    align-items: center; /* Added to vertically center "+1" and input */
}  
.input {
    height: 50px;
    border-radius: 10px;
    border: 1px solid var(--primaryColorLight);
    width: 100%;
    padding-left: 75px;
    font-size: 16px;
}
.input:focus {
    border-bottom: 2px solid var(--primaryColorMedium);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    outline: none; /* Remove the default outline on focus */
}  
.headerActive {
    color: var(--primaryColorMedium);
    font-weight: bold;
}