.container{
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    padding: 3rem 0px 3rem 0px;
    min-height: 50rem;
}

.location_top_flex_column_container{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 70%;
}

.activity_location_container{
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

}

.location_details_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 4rem;
}

.input_wrapper{
    width: 30rem;
}

.buttons_wrapper{
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.location_btn{
    height: 5rem;
    padding: 1.5rem;
    border-radius: var(--borderRadius);
    background-color: var(--inactiveColorLight);
    border: none; 
    font-size: clamp(1.2rem, 2vw, 1.4rem);
    display: flex;
    align-items: center;
}

.location_btn:hover{
    color: var(--primaryColor);
    font-weight: bold;
    transition: 0.1s ease-in-out;
}

.location_bottom_container{
    display: flex;
    justify-content: center;
}

.map_wrapper{
    height: 30rem;
    width: 70%;
}

.address_suggestions_container{
    margin-top: 3rem;
    padding: 0 1rem;
}

.address_suggestion{
    font-size: 1.6rem;
    padding: 0.5rem;
    cursor: pointer;
    margin-left: -0.5rem;
}

.address_suggestion:hover{
    background-color: var(--primaryColorLight);
}

.location_warning{
    margin-top: 5rem;
    text-align: center;
    font-size: clamp(1.2rem, 2vw, 1.4rem)
}

/* Small Screens   */
@media only screen and (max-width: 900px){
    .location_details_wrapper{
        flex-wrap: wrap;
        gap: 1rem;
    }
    .buttons_wrapper{
        width: 100%;
    }
    .location_btn{
        justify-content: center;
        width: 50%;
    }
    .map_wrapper{
        height: 25rem;
    }
}

/* Smaller Screens / Phones   */
@media only screen and (max-width: 650px){
    .location_details_wrapper{
        flex-wrap: wrap;
        gap: 1.2rem;
    }
    .buttons_wrapper{
        width: 100%;
    }
    .location_btn{
        justify-content: center;
        width: 50%;
        height: 3.5rem;
        margin-top: 0.5rem;
    }
    .location_details_wrapper h6, .location_details_wrapper p{
        width: 100%;
    }
    .map_wrapper{
        height: 22rem;
        width: 100%;
    }
}

/* Tiny Screens / Small Phones   */
@media only screen and (max-width: 390px){
    .activity_location_container{
        margin-top: 0rem;
    }
    .location_details_wrapper{
       border: 1px solid red;
        flex-wrap: nowrap;
        gap: 2rem;
        margin-left: -4rem;
        width: 30rem;
        
    }
  }








