.terms_container{
    display: flex;
    flex-direction: column;
    padding: 10rem 5rem 0rem 5rem;
    gap: 3rem;
}

.terms_container > p:nth-child(3) {
  margin-top: 5rem;
}

.terms_container > h3{
    margin-top: 5rem;
}

.terms_header{
    color: var(--primaryColor);
    margin-bottom: 0;
}

.terms_header_effective_date{
    color: var(--primaryColor);
    margin-top: 1rem;
    font-size: 1.3rem;
}

.terms_list_items_container{
    margin: 2rem;
}

.terms_list_items_container > li {
    font-size: clamp(1.4rem, 2.8vw, 2rem);
    color: var(--fontColor);
}

.terms_address_container > p {
    margin: 0rem;
}

.terms_address_container > p:first-child{
    font-weight: bold;
    margin-bottom: 0.5rem;
}