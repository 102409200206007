.hero{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding-top: 6rem;
    padding-left: 3rem;
    padding-right: 3rem;
}

.hero h1 {
    letter-spacing: 1rem;
}

.title_container{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.logo {
    width: 15rem;
    height: auto;
    -webkit-animation:spin 0.8s linear ;
    -moz-animation:spin 0.8s linear ;
    animation: spin 0.8s linear ;
}

.line {
    width: 0.15rem;
    height: 10rem; 
    background-color: var(--inactiveColorStrong); 
}

.text_container{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
}

.statement {
    font-weight: bold;
    letter-spacing: 0.25rem;
    color: var(--defaultColor);
}

.sub_header{
    color: var(--fontColorLightBlack);
    padding-bottom: 4rem;
}


.romp_header_container{
    display: flex;
    animation: shake 0.8s cubic-bezier(.36,.07,.19,.97) both;
    animation-delay: 1.2s;
}

.romp_header_letter:nth-child(1){
    font-weight: bold;
    animation: animateLetterDown 1s  ease-in-out;
}

.romp_header_letter:nth-child(2){
    font-weight: bold;
    animation: animateLetterUp 1s  ease-in-out;
}
.romp_header_letter:nth-child(3){
    font-weight: bold;
    animation: animateLetterDown 1s  ease-in-out;
}
.romp_header_letter:nth-child(4){
    font-weight: bold;
    animation: animateLetterUp 1s  ease-in-out;
}

/* ANIMATIONS */

@keyframes animateLetterDown {
    0% {
      transform: translateY(-100rem);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;

    }
}

@keyframes animateLetterUp{
    0% {
      transform: translateY(100rem);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

/* Mobile Devices up to 480px */
@media (max-width: 480px) {  
    .logo {
        width: 10rem;
    }

    .line {
        height: 8rem;
    }
}