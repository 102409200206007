.carousel {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: none;
}

.events_container {
  display: flex;
  justify-content: center;
}


.event_item {
  animation: waveFadeIn 0.75s ease-in-out forwards;
  opacity: 0.1;
  width: 100%;
  cursor: pointer;
  transform: scale(2);
}

@keyframes waveFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Adjust the animation delay for each child element */
.event_item:nth-child(1) {
  transform: translateY(3.5rem) translateX(-3.5rem);
}

.event_item:nth-child(2) {
  transform: translateY(1rem) translateX(-2rem);
}

.event_item:nth-child(3) {
  transform: translateY(-1rem) translateX(-0.65rem);
}

.event_item:nth-child(4) {
  transform: translateY(-1rem) translateX(0.65rem);
}

.event_item:nth-child(5) {
  transform: translateY(1rem) translateX(2rem);
}

.event_item:nth-child(6) {
  transform: translateY(3.5rem) translateX(3.5rem);
}


/* Mobile Devices (landscape) */
@media (max-width: 775px) {
  .event_item:nth-child(2) {
    transform: translateY(1rem) translateX(-1.5rem);
  }

  .event_item:nth-child(3) {
    transform: translateY(-1rem) translateX(-0.45rem);
  }

  .event_item:nth-child(4) {
    transform: translateY(-1rem) translateX(0.45rem);
  }

  .event_item:nth-child(5) {
    transform: translateY(1rem) translateX(1.5rem);
  }
}


