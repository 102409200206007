.container{
    font-size: 18px;
    outline: none;
    border: none;
    font-weight: bold;
    padding: 1.5rem 2.5rem; 
    border-radius: var(--borderRadiusPillButton);
    transition: all 0.5s ease-in-out; 
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); 
}
.secondary {
    text-align: center;
    color: var(--defaultColor);
    background-color: var(--inactiveColorLight);
}
.primary {
    text-align: center;
    background-color: var(--buttonColorPillDashboard);
    color: white;
}
.secondary:focus, .secondary:hover{
    outline: none;
    border: none;
    background-color: var(--inactiveColorExtraStrong); 
    color: white; 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);   
}
.primary:focus, .primary:hover {
    outline: none;
    border: none;
    background-color: var(--buttonColorPillDashboardExtraLight);  
    color: var(--defaultColor);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);     
}
