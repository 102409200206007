.header{
    font-weight: 900;
    font-size: 24px;
    text-align: center;
}

.subheader{
    margin-top: 10px;
    color: black;
    font-size: 18px;
    text-align: center;
}