.container{
    width: 100vw;
    height: 100vh;
    background-color: var(--primaryColorExtraLight);
    display: flex;
    flex-direction: column;
}
.header_container{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.secondary_container{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
}
.secondary_text{
    color: var(--inactiveColorExtraStrong);
    font-size: 18px;
}
.mainLogo{
    width: 100px;
}
.login_container{
    border: 1px solid var(--primaryColorLight);
    border-radius: 10px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    height: 430px;
    width: 430px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    /* margin: auto; */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: var(--inactiveColorExtraLight);
}
.log_in_text{
    font-size: 28px;
    color: var(--defaultColor);
    margin-bottom: 20px;
}
.form_container{
    width: 100%;
    margin-top: 20px;
}
.header{
    font-weight: 900;
    text-align: center;
    font-size: 6rem;
}
.subheader{
    margin-top: 1rem;
    text-align: center;
    color: grey;
    font-weight: bold;
    font-size: 1.8rem;
}
.pressable_text{
    color: var(--inactiveColorExtraStrong);
    cursor: pointer;
    font-size: 16px;
}
.pressable_text:hover{
    color: var(--primaryColor);
    cursor: pointer;
}
.login_button_container{
    margin-top: 20px;
}
