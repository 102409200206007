.main_container{
    display: flex;
    flex-direction: column;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    padding: 30px 0px 30px 0px;
    min-height: 500px;
}

.form_container{
    margin-top: 20px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.pressable_container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;
}


