.past_activities {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/*TODO: change width/height when content is established via Recoil -- remove fixed height */
.past_activities > div{
  width: 326px;
  height: 25rem;
  /* border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: var(--inactiveColorExtraLight);
  border: 1px solid var(--primaryColorLight);
   */
   background: var(--glassyBackgroundColor);
   border-radius: 20px;
   box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
   backdrop-filter: blur(20.6px);
   -webkit-backdrop-filter: blur(20.6px);
   border: 1px solid rgba(97, 166, 171, 0.2);
}

.past_activities > div:hover{
  cursor: pointer;
  outline: 0.3rem solid var(--primaryColorMedium);
}