.container{
    width: 70%;
    align-items: 'center';
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
}

.left_icon_container{
    width: 50px;
    height: 20px;
    margin-left: auto;
    top: -7.5px;
    position: relative;
}

.right_icon_container{
    width: 50px;
    height: 20px;
    margin-right: auto;
}

.icon{
    font-size: 36px;
    color: var(--inactiveColorStrong);
    cursor: pointer;
}

.icon:hover{
    color: var(--inactiveColorMedium);
}

.icon:hover{
    cursor: pointer;
}

.progress_bar_container{
    width: 100%;
    border-radius: 20px;
    height: 20px;
    background-color: var(--inactiveColorMedium);
}

.progress_bar_status{
    border-radius: 20px;
    height: 100%;
    background-color: var(--newScreenColor);
    transition: 0.8s ease;
}

.progress_bar_inner_status{
    border-radius: 20px;
    margin-left: 10px;
    margin-right: 10px;
    height: 33.33%;
    position: relative;
    top: 5px;
    background-color: #FFFFFF;
    z-index: 10;
    opacity: 0.3;
}
